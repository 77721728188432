import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditFormLayout } from "mrb/components/layout";
import { MrbFieldInput, MrbFieldTextArea, MrbFieldNumericInput } from "mrb/components/input";
import { MrbFieldSelect } from "mrb/components/select";
import { MrbFieldToggle } from "mrb/components/checkbox";
import { MrbFieldRadioButtons } from "mrb/components/radio";
import { PodcastCoverImageUpload } from "administration/podcast/components";
import { FieldRichTextEditor, ProducerFieldSelect } from "common/components";
import { defaultTemplate } from "common/hoc";

function AddPodcastGroupItemTemplate({ addPodcastGroupItemViewStore, t }) {
    const {
        form,
        episodeSortTypeOptions,
        resetForm,
        coverImageUploadViewStore,
        genreSelectStore,
        languageSelectStore,
        locationSelectStore,
        producerSelectStore,
        isPremiumDisabled,
    } = addPodcastGroupItemViewStore;
    return (
        <MrbEditFormLayout
            form={form}
            t={t}
            onClickCancel={resetForm}
            submitLabel="ADMINISTRATION.PODCAST.EDIT.ADD_PODCAST_TO_GROUP.BUTTONS.ADD"
            cancelLabel="ADMINISTRATION.PODCAST.EDIT.ADD_PODCAST_TO_GROUP.BUTTONS.RESET"
        >
            <div className="u-animation--fadeIn">
                <div className="row">
                    <div className="col col-sml-12 col-lrg-8 col-xxlrg-9">
                        <MrbFieldInput t={t} field={form.$("title")} classNameExtend="c-input--outline" />

                        <MrbFieldInput t={t} field={form.$("podcastFeed.feedUrl")} classNameExtend="c-input--outline" />

                        <MrbFieldInput t={t} field={form.$("descriptionLink")} classNameExtend="c-input--outline" />
                    </div>

                    <div className="col col-sml-12 col-lrg-4 col-xxlrg-3 c-upload--order-3">
                        <div className="c-upload c-upload--sml">
                            <PodcastCoverImageUpload
                                classNameExtendUpload="c-upload__upload--sml"
                                coverImageUploadViewStore={coverImageUploadViewStore}
                            />
                        </div>
                    </div>

                    <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                        <MrbFieldSelect t={t} store={locationSelectStore} classNameExtend="c-dropdown--dark" />
                    </div>
                    <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                        <MrbFieldSelect t={t} store={languageSelectStore} classNameExtend="c-dropdown--dark" />
                    </div>
                    <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                        <MrbFieldSelect t={t} store={genreSelectStore} classNameExtend="c-dropdown--dark" />
                    </div>
                    <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                        <ProducerFieldSelect t={t} store={producerSelectStore} classNameExtend="c-dropdown--dark" />
                    </div>

                    <div className="col col-sml-12">
                        <MrbFieldInput t={t} field={form.$("keywords")} classNameExtend="c-input--outline" />
                    </div>

                    <div className="col col-sml-12">
                        <MrbFieldTextArea
                            t={t}
                            field={form.$("description")}
                            classNameExtend="c-input--outline"
                            labelClassNameExtend="u-mar--top--tny"
                        />
                    </div>

                    <div className="col col-sml-12">
                        <FieldRichTextEditor field={form.$("alternativeDescription")} t={t} />
                    </div>
                </div>

                <div className="c-form__footer__list">
                    <MrbFieldToggle
                        classNameExtend="u-mar--right--lrg"
                        labelClassNameExtend="u-mar--top--tny"
                        t={t}
                        field={form.$("isActive")}
                    />
                    <MrbFieldToggle
                        classNameExtend="u-mar--right--lrg u-mar--bottom--sml"
                        labelClassNameExtend="u-mar--top--tny"
                        t={t}
                        field={form.$("isPremium")}
                        disabled={isPremiumDisabled}
                    />
                    <MrbFieldToggle
                        classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                        labelClassNameExtend="u-mar--top--tny"
                        t={t}
                        field={form.$("isTurnedOnByDefault")}
                    />
                    <MrbFieldToggle
                        classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                        labelClassNameExtend="u-mar--top--tny"
                        t={t}
                        field={form.$("isTurnedOnByDefaultInApp")}
                    />
                    <MrbFieldToggle
                        classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                        labelClassNameExtend="u-mar--top--tny"
                        t={t}
                        field={form.$("podcastFeed.recentlyUpdatedVisibility")}
                    />
                    <MrbFieldToggle
                        classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                        labelClassNameExtend="u-mar--top--tny"
                        t={t}
                        field={form.$("isVisibleInApp")}
                    />
                    <MrbFieldToggle
                        classNameExtend="c-toggle__item"
                        labelClassNameExtend="u-mar--top--tny"
                        t={t}
                        field={form.$("podcastFeed.hlsEnabled")}
                    />
                    <div className="u-mar--right--lrg">
                        <MrbFieldRadioButtons
                            field={form.$("podcastFeed.episodeSortTypeId")}
                            t={t}
                            options={episodeSortTypeOptions}
                        />
                    </div>

                    <MrbFieldNumericInput precision={0} t={t} field={form.$("podcastFeed.episodeLimit")} />
                </div>

                <div className="u-separator--primary"></div>
            </div>
        </MrbEditFormLayout>
    );
}

AddPodcastGroupItemTemplate.propTypes = {
    addPodcastGroupItemViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(AddPodcastGroupItemTemplate);
