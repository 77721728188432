import { action } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbSelectStore } from "mrb/components/select";
import { isNil } from "lodash";

class EditFeaturedSectionContentViewStore extends MrbBaseViewStore {
    item = null;
    onUpdate = null;

    constructor(rootStore, { routeStore, item, onUpdate }) {
        super(rootStore);
        this.routeStore = routeStore;
        this.item = item;
        this.onUpdate = onUpdate;

        this.contentSelectStore = new MrbSelectStore({
            isSearchable: true,
            textKey: "title",
            valueKey: "id",
            initFetch: false,
            actions: {
                find: (searchTerm, pageNumber, pageSize) => {
                    return this.routeStore.getContent({
                        search: searchTerm,
                        pageNumber: pageNumber,
                        pageSize: pageSize,
                    });
                },
                mapItem: (item) => {
                    return {
                        label: item.title,
                        value: item.stationId || item.podcastId || item.artistId || item.albumId,
                        item: item,
                    };
                },
            },
        });
        this.onSelectItem = this.onSelectItem.bind(this);
    }

    @action.bound
    onSelectItem() {
        const item = this.contentSelectStore.selectedItem;

        this.updateItem(item);
        this.contentSelectStore.clearOptions();
        this.contentSelectStore.clear();
        this.contentSelectStore.filter();
    }

    @action.bound
    updateItem(item) {
        this.item.stationId = null;
        this.item.station = null;
        this.item.podcastId = null;
        this.item.podcast = null;
        this.item.nakiNigunArtistId = null;
        this.item.artist = null;
        this.item.nakiNigunAlbumId = null;
        this.item.album = null;
        if (!isNil(item.stationId)) {
            this.item.stationId = item.stationId;
            this.item.station = {
                id: item.stationId,
                title: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
            };
        } else if (!isNil(item.podcastId)) {
            this.item.podcastId = item.podcastId;
            this.item.podcast = {
                id: item.podcastId,
                title: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
                podcastType: item.podcastType,
            };
        } else if (!isNil(item.artistId)) {
            this.item.nakiNigunArtistId = item.artistId;
            this.item.artist = {
                id: item.artistId,
                name: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
            };
        } else if (!isNil(item.albumId)) {
            this.item.nakiNigunAlbumId = item.albumId;
            this.item.album = {
                id: item.albumId,
                title: item.title,
                isPremium: item.isPremium,
                coverImageId: item.coverImageId,
            };
        }

        this.onUpdate();
    }
}

export default EditFeaturedSectionContentViewStore;
