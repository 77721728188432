import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader } from "mrb/components/layout";
import { MrbToggle } from "mrb/components/checkbox";
import { MrbButton } from "mrb/components/button";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { PodcastGroupPreviewSubPodcastList } from "administration/podcast/components";
import { defaultTemplate } from "common/hoc";
import { DescriptionLoader, HTMLRender, ToggleActionsLoader, Image } from "common/components";

function PodcastGroupPreviewTemplate({ podcastGroupPreviewViewStore, t }) {
    const {
        item,
        loaderStore,
        toggleIsActive,
        togglePremiumStatus,
        toggleVisibilityStatus,
        toggleFeaturedStatus,
        toggleAppVisibilityStatus,
        coverImageUrl,
        deletePodcast,
    } = podcastGroupPreviewViewStore;

    return (
        <MrbPreviewPage store={podcastGroupPreviewViewStore} contentClassNameExtend="u-padd--none" t={t}>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="c-content__header--primary__img">
                                <div className="u-animation--fadeIn">
                                    <Image src={coverImageUrl} />
                                </div>
                            </div>
                            <div className="c-content__header--primary__info">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.GROUP_TITLE")}
                                        </p>
                                        <p className="c-content__header--primary__title">{item.title}</p>
                                    </div>

                                    <div className="c-content__header--primary__descriprtion">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.DESCRIPTION_LINK")}
                                        </p>
                                        {item.descriptionLink && (
                                            <a href={item.descriptionLink} target="_blank" rel="noreferrer">
                                                {item.descriptionLink}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <div className="c-container--sidebar">
                <div className="c-card--primary c-card--med">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <DescriptionLoader />}>
                        {() => (
                            <div className="u-animation--fadeIn">
                                <div className="row">
                                    <div className="col col-sml-12">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.PORTAL_DESCRIPTION")}
                                            </p>
                                            <HTMLRender className="c-collapse-panel__body__description">
                                                {item.alternativeDescription}
                                            </HTMLRender>
                                        </div>
                                    </div>
                                    <div className="col col-sml-12">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.PREMIUM_INFO")}
                                            </p>
                                            <HTMLRender className="c-collapse-panel__body__description">
                                                {item.premiumInfo}
                                            </HTMLRender>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </MrbContentLoader>
                </div>

                <div>
                    <div className="c-card--primary">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <ToggleActionsLoader lineheight={25} smallWidth={true} rows={6} />}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <div className="c-sidebar__item">
                                        <div className="u-type--base">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.STATUS")}
                                        </div>
                                        <MrbToggle
                                            id="podcast-active-status"
                                            value={item.isActive}
                                            onChange={(event) => toggleIsActive(event.target.checked)}
                                        />
                                    </div>
                                    <div className="c-sidebar__item">
                                        <div className="u-type--base">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.PREMIUM")}
                                        </div>
                                        <MrbToggle
                                            id="podcast-premium-status"
                                            value={item.isPremium}
                                            onChange={(event) => togglePremiumStatus(event.target.checked)}
                                        />
                                    </div>
                                    <div className="c-sidebar__item">
                                        <div className="u-type--base">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.VISIBLE_ON_PORTAL")}
                                        </div>
                                        <MrbToggle
                                            id="podcast-visibility-status"
                                            value={item.isVisible}
                                            onChange={(event) => toggleVisibilityStatus(event.target.checked)}
                                        />
                                    </div>
                                    <div className="c-sidebar__item">
                                        <div className="u-type--base">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.FEATURED_ON_PORTAL")}
                                        </div>
                                        <MrbToggle
                                            id="podcast-featured-status"
                                            value={item.isFeatured}
                                            onChange={(event) => toggleFeaturedStatus(event.target.checked)}
                                        />
                                    </div>
                                    <div className="c-sidebar__item">
                                        <div className="u-type--base">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.VISIBLE_IN_APP")}
                                        </div>
                                        <MrbToggle
                                            id="podcast-app-visibility-status"
                                            value={item.isVisibleInApp}
                                            onChange={(event) => toggleAppVisibilityStatus(event.target.checked)}
                                        />
                                    </div>
                                    <div className="c-sidebar__item">
                                        <div className="u-type--base">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.DELETE_LABEL")}
                                        </div>
                                        <MrbButton
                                            t={t}
                                            className="c-btn c-btn--primary c-btn--sml"
                                            label="ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.DELETE"
                                            onClick={deletePodcast}
                                        />
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>
                </div>
            </div>

            <PodcastGroupPreviewSubPodcastList podcastGroupPreviewViewStore={podcastGroupPreviewViewStore} t={t} />
        </MrbPreviewPage>
    );
}

PodcastGroupPreviewTemplate.propTypes = {
    podcastGroupPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PodcastGroupPreviewTemplate);
