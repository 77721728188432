import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbFieldToggle } from "mrb/components";
import { MrbContentLoader } from "mrb/components/loader";
import {
    AddPodcastGroupItem,
    PodcastGroupEditSubPodcastList,
    PodcastGroupEditPageLayout,
    PodcastCoverImageUpload,
    AddPodcastsToTheGroupLoader,
} from "administration/podcast/components";
import { MrbFileUploadValidation } from "mrb/components/media";
import { AddStationsOrPodcastsLoader, CoverImageUploadLoader, FieldRichTextEditor } from "common/components";
import { defaultTemplate } from "common/hoc";
function PodcastGroupEditTemplate({ podcastGroupEditViewStore, t }) {
    const { form, loaderStore, coverImageUploadViewStore } = podcastGroupEditViewStore;
    const { fileUploadStore } = coverImageUploadViewStore;

    return (
        <PodcastGroupEditPageLayout store={podcastGroupEditViewStore} t={t}>
            <div className="c-container--sidebar u-padd--none u-mar--bottom--med">
                <div className="c-card--primary u-height--100">
                    <MrbContentLoader
                        loaderStore={loaderStore}
                        loaderRender={() => <AddStationsOrPodcastsLoader podcast podcastGroup />}
                    >
                        {() => (
                            <form onSubmit={form.onSubmit}>
                                <div className="u-animation--fadeIn">
                                    <div className="row">
                                        <div className="col col-sml-12">
                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("title")}
                                                classNameExtend="c-input--outline"
                                            />
                                        </div>
                                        <div className="col col-sml-12">
                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("descriptionLink")}
                                                classNameExtend="c-input--outline"
                                            />
                                        </div>
                                        <div className="col col-sml-12">
                                            <FieldRichTextEditor field={form.$("alternativeDescription")} t={t} />
                                        </div>
                                        {form.$("isPremium").value ? (
                                            <div className="col col-sml-12">
                                                <FieldRichTextEditor field={form.$("premiumInfo")} t={t} />
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="c-form__footer__list">
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item"
                                            labelClassNameExtend="u-mar--top--tny"
                                            t={t}
                                            field={form.$("isActive")}
                                        />
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item"
                                            labelClassNameExtend="u-mar--top--tny"
                                            t={t}
                                            field={form.$("isPremium")}
                                        />
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item"
                                            labelClassNameExtend="u-mar--top--tny"
                                            t={t}
                                            field={form.$("isVisible")}
                                        />
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item"
                                            labelClassNameExtend="u-mar--top--tny"
                                            t={t}
                                            field={form.$("isFeatured")}
                                        />
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item"
                                            labelClassNameExtend="u-mar--top--tny"
                                            t={t}
                                            field={form.$("isVisibleInApp")}
                                        />
                                    </div>
                                </div>
                            </form>
                        )}
                    </MrbContentLoader>
                </div>

                <div>
                    <div className="c-upload c-upload--lrg">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <CoverImageUploadLoader />}>
                            {() => (
                                <PodcastCoverImageUpload
                                    classNameExtendUpload="c-upload__upload--lrg"
                                    coverImageUploadViewStore={coverImageUploadViewStore}
                                    showInfo
                                />
                            )}
                        </MrbContentLoader>
                    </div>
                    <MrbFileUploadValidation store={fileUploadStore} />
                </div>
            </div>

            <div className="u-mar--bottom--med">
                <h1 className="u-type--base u-mar--bottom--sml">
                    {t("ADMINISTRATION.PODCAST.EDIT.ADD_PODCAST_TO_GROUP.TITLE")}
                </h1>
            </div>

            <div className="c-card--primary u-mar--bottom--xlrg">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AddPodcastsToTheGroupLoader />}>
                    {() => <AddPodcastGroupItem podcastGroupEditViewStore={podcastGroupEditViewStore} />}
                </MrbContentLoader>
            </div>

            <div>
                <PodcastGroupEditSubPodcastList podcastGroupEditViewStore={podcastGroupEditViewStore} t={t} />
            </div>
        </PodcastGroupEditPageLayout>
    );
}

PodcastGroupEditTemplate.propTypes = {
    podcastGroupEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PodcastGroupEditTemplate);
