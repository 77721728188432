import { LocalizedForm } from "common/localization";

export default class StationForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "title",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.TITLE_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.TITLE_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "description",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.DESCRIPTION_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.DESCRIPTION_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "alternativeDescription",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.PORTAL_DESCRIPTION_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.PORTAL_DESCRIPTION_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "premiumInfo",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PREMIUM_INFO_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PREMIUM_INFO_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "descriptionLink",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.DESCRIPTION_LINK_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.DESCRIPTION_LINK_PLACEHOLDER",
                    rules: "string|url",
                },
                {
                    name: "streamUrl",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.STREAM_URL_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.STREAM_URL_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "isActive",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_ACTIVE_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_ACTIVE_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isFeatured",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_FEATURED_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_FEATURED_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isPremium",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_PREMIUM_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_PREMIUM_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isVisible",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_VISIBLE_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_VISIBLE_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isTurnedOnByDefault",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isTurnedOnByDefaultInApp",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_IN_APP_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_IN_APP_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "overrideRadioUsersSettings",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    type: "checkbox",
                },
                {
                    name: "overrideAppUsersSettings",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    type: "checkbox",
                },
                {
                    name: "isVisibleInApp",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_VISIBLE_IN_APP",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.IS_VISIBLE_IN_APP",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "locationId",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.LOCATION_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.LOCATION_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "languageId",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.LANGUAGE_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.LANGUAGE_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "genreIds",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.GENRE_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.GENRE_PLACEHOLDER",
                },
                {
                    name: "keywords",
                    label: "ADMINISTRATION.STATION.EDIT.FIELDS.KEYWORDS_LABEL",
                    placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.KEYWORDS_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "stationPromo",
                    fields: [
                        {
                            name: "promoImageFileEntryId",
                            label: "ADMINISTRATION.STATION.EDIT.FIELDS.PROMO.PROMO_IMAGE_LABEL",
                            placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.PROMO.PROMO_IMAGE_PLACEHOLDER",
                            rules: "string",
                        },
                        {
                            name: "description",
                            label: "ADMINISTRATION.STATION.EDIT.FIELDS.PROMO.DESCRIPTION_LABEL",
                            placeholder: "ADMINISTRATION.STATION.EDIT.FIELDS.PROMO.DESCRIPTION_PLACEHOLDER",
                            rules: "string",
                        },
                    ],
                },
            ],
        };
    }
}
