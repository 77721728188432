import React from "react";
import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { SubPodcastAnalyticsStatsWidgetViewStore } from "administration/podcast/stores";
import { applicationErrorCodes } from "common/constants";
import { some } from "lodash";
import { localizationService } from "common/localization";

class PodcastGroupPreviewSubPodcastViewStore extends MrbBaseViewStore {
    @observable isCollapsed = false;
    @observable podcast = null;
    panelRef = React.createRef();

    @computed get coverImageUrl() {
        if (this.podcast && this.podcast.coverImageId) {
            return this.routeStore.createPodcastCoverImageUrl(this.podcast.coverImageId, 150, 150);
        }

        return null;
    }

    @computed get isPremiumDisabled() {
        return this.podcastGroupPreviewViewStore.item.isPremium;
    }

    @computed get isAppVisibilityDisabled() {
        return !this.podcastGroupPreviewViewStore.item.isVisibleInApp;
    }

    constructor(rootStore, { podcastGroupPreviewViewStore, podcast }) {
        super(rootStore);
        makeObservable(this);
        this.podcastGroupPreviewViewStore = podcastGroupPreviewViewStore;
        this.routeStore = podcastGroupPreviewViewStore.routeStore;
        this.podcast = podcast;

        this.subPodcastAnalyticsStatsWidgetViewStore = new SubPodcastAnalyticsStatsWidgetViewStore(rootStore, {
            routeStore: this.routeStore,
            podcastId: this.podcast.id,
        });

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed) {
                    this.subPodcastAnalyticsStatsWidgetViewStore.initialize();
                    if (this.panelRef) {
                        // We need to execute scroll function asynchronously in order to take effect.
                        setTimeout(() => {
                            this.panelRef.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        });
                    }
                }
            }
        );

        this.reaction(
            () => this.podcastGroupPreviewViewStore.item.isPremium,
            (isPodcastGroupPremium) => {
                if (isPodcastGroupPremium && !this.podcastGroupPreviewViewStore.isPremiumDisabled) {
                    runInAction(() => {
                        this.podcast.isPremium = true;
                    });
                }
            }
        );

        this.reaction(
            () => this.podcastGroupPreviewViewStore.item.isVisibleInApp,
            (isVisibleInApp) => {
                runInAction(() => {
                    this.podcast.isVisibleInApp = isVisibleInApp;
                });
            }
        );

        this.reaction(
            () => this.podcast.isVisibleInApp,
            (isVisibleInApp) => {
                if (!isVisibleInApp) {
                    if (
                        !some(
                            this.podcastGroupPreviewViewStore.item.subPodcasts,
                            (subPodcast) => subPodcast.isVisibleInApp
                        )
                    ) {
                        runInAction(() => {
                            this.podcastGroupPreviewViewStore.item.isVisibleInApp = false;
                        });
                    }
                }
            }
        );
    }

    @action.bound
    onInit() {
        const subPodcastId = this.rootStore.routerStore.routerState.queryParams.subPodcastId;
        if (subPodcastId && this.podcast.id === subPodcastId) {
            this.isCollapsed = true;
        }
        return Promise.resolve();
    }

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    @action.bound
    async toggleIsActive(value) {
        const oldValue = this.podcast.isActive;
        this.podcast.isActive = value;
        try {
            await this.routeStore.updateActiveStatus(this.podcast.id, this.podcast.isActive);
        } catch (err) {
            if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
                this.rootStore.notificationStore.error(
                    "Podcast updated, but an unexpected error occurred during RSS sync.",
                    err
                );
            } else {
                runInAction(() => {
                    this.podcast.isActive = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change status", err);
                });
            }
        }
    }

    @action.bound
    async togglePremiumStatus(value) {
        const oldValue = this.podcast.isPremium;
        this.podcast.isPremium = value;
        try {
            await this.routeStore.updatePremiumStatus(this.podcast.id, this.podcast.isPremium);
        } catch (err) {
            if (this.podcast.isPremium && this.podcast.producerId === null) {
                runInAction(() => {
                    this.podcast.isPremium = oldValue;
                    this.rootStore.notificationStore.warning(
                        localizationService.t("ADMINISTRATION.PODCAST.LIST.VALIDATION.PODCAST_MISSING_PRODUCER")
                    );
                });
            } else {
                runInAction(() => {
                    this.podcast.isPremium = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change premium", err);
                });
            }
        }
    }

    @action.bound
    async toggleOnByDefaultStatus(value) {
        const oldValue = this.podcast.isTurnedOnByDefault;
        this.podcast.isTurnedOnByDefault = value;
        try {
            await this.routeStore.updateOnByDefaultStatus(this.podcast.id, this.podcast.isTurnedOnByDefault);
        } catch (err) {
            runInAction(() => {
                this.podcast.isTurnedOnByDefault = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleOnByDefaultInAppStatus(value) {
        const oldValue = this.podcast.isTurnedOnByDefaultInApp;
        this.podcast.isTurnedOnByDefaultInApp = value;
        try {
            await this.routeStore.updateOnByDefaultInAppStatus(this.podcast.id, this.podcast.isTurnedOnByDefaultInApp);
        } catch (err) {
            runInAction(() => {
                this.podcast.isTurnedOnByDefaultInApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleRecentlyUpdatedVisibilityStatus(value) {
        const oldValue = this.podcast.podcastFeed.recentlyUpdatedVisibility;
        this.podcast.podcastFeed.recentlyUpdatedVisibility = value;
        try {
            await this.routeStore.updateRecentlyUpdatedVisibilityStatus(
                this.podcast.id,
                this.podcast.podcastFeed.recentlyUpdatedVisibility
            );
        } catch (err) {
            runInAction(() => {
                this.podcast.podcastFeed.recentlyUpdatedVisibility = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleAppVisibilityStatus(value) {
        const oldValue = this.podcast.isVisibleInApp;
        this.podcast.isVisibleInApp = value;
        try {
            await this.routeStore.updateAppVisibilityStatus(this.podcast.id, this.podcast.isVisibleInApp);
        } catch (err) {
            runInAction(() => {
                this.podcast.isVisibleInApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change app visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleHlsEnabledStatus(value) {
        const oldValue = this.podcast.podcastFeed.hlsEnabled;
        this.podcast.podcastFeed.hlsEnabled = value;
        try {
            await this.routeStore.updateHlsEnabledStatus(this.podcast.id, this.podcast.podcastFeed.hlsEnabled);
        } catch (err) {
            runInAction(() => {
                this.podcast.podcastFeed.hlsEnabled = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change HLS enabled status.",
                    err
                );
            });
        }
    }

    onClickEdit = () => {
        this.rootStore.routerStore.goTo(
            "master.administration.podcast.edit-group",
            { id: this.podcast.parentPodcastId },
            { subPodcastId: this.podcast.id }
        );
    };

    onClickPodcastEpisodeList = () => {
        this.rootStore.routerStore.goTo("master.administration.podcast.episodes", {
            id: this.podcast.id,
        });
    };

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };

    onDestroy() {
        this.subPodcastAnalyticsStatsWidgetViewStore.destroy();
        super.onDestroy();
    }
}

export default PodcastGroupPreviewSubPodcastViewStore;
