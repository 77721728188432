import React from "react";
import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { MrbFieldSelectStore } from "mrb/components/select";
import { PodcastGroupItemEditForm } from "administration/podcast/forms";
import { PodcastCoverImageUploadViewStore } from "administration/podcast/stores";
import { find, findIndex, isNil, map } from "lodash";
class PodcastGroupEditSubPodcastViewStore extends MrbBaseEditViewStore {
    @observable overrideRadioUsersSettingsIsVisible = false;
    @observable overrideAppUsersSettingsIsVisible = false;
    @observable isCollapsed = false;
    panelRef = React.createRef();

    @computed get podcast() {
        return find(this.podcastGroupEditViewStore.subPodcasts, (subPodcast) => subPodcast.id === this.podcastId);
    }

    @computed get isNewlyAdded() {
        return isNil(this.podcast.dateCreated);
    }

    @computed get podcastIndex() {
        return (
            findIndex(this.podcastGroupEditViewStore.subPodcasts, (subPodcast) => subPodcast.id === this.podcastId) + 1
        );
    }

    @computed get episodeSortTypeOptions() {
        return this.podcastGroupEditViewStore.episodeSortTypeOptions;
    }

    @computed get disabledForShufflingWeight() {
        return this.podcastGroupEditViewStore.disabledForShufflingWeight;
    }

    @computed get isPremiumDisabled() {
        return this.podcastGroupEditViewStore.form.$("isPremium").value;
    }

    constructor(rootStore, { podcastGroupEditViewStore, podcastId }) {
        super(rootStore, {
            name: "podcast-group-sub-podcast",
            FormClass: PodcastGroupItemEditForm,
            redirectOnCreateSuccess: false,
        });
        makeObservable(this);
        this.podcastId = podcastId;
        this.podcastGroupEditViewStore = podcastGroupEditViewStore;
        this.routeStore = this.podcastGroupEditViewStore.routeStore;

        this.coverImageUploadViewStore = new PodcastCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
            width: 200,
            height: 200,
        });

        this.genreSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                isMulti: true,
                initFetch: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findGenres(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createGenre({ name });
                    },
                },
            },
            this.form.$("genreIds")
        );

        this.locationSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                initFetch: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLocations(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLocation({ name });
                    },
                },
            },
            this.form.$("locationId")
        );

        this.languageSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                initFetch: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLanguages(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLanguage({ name });
                    },
                },
            },
            this.form.$("languageId")
        );

        this.producerSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                initFetch: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findProducers(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createProducer({ name });
                    },
                },
            },
            this.form.$("producerId")
        );

        if (!this.isNewlyAdded) {
            this.reaction(
                () => this.form.$("isTurnedOnByDefault").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.podcast.isTurnedOnByDefault) {
                        runInAction(() => {
                            this.overrideRadioUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = false;
                            this.form.$("overrideRadioUsersSettings").value = false;
                        });
                    }
                }
            );
            this.reaction(
                () => this.form.$("isTurnedOnByDefaultInApp").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.podcast.isTurnedOnByDefaultInApp) {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = false;
                            this.form.$("overrideAppUsersSettings").value = false;
                        });
                    }
                }
            );
        }

        this.setForm();

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed) {
                    this.form.$("title").focus();
                    if (this.panelRef) {
                        // We need to execute scroll function asynchronously in order to take effect.
                        setTimeout(() => {
                            this.panelRef.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        });
                    }
                }
            }
        );

        this.reaction(
            () => this.form.changed,
            (isChanged) => {
                if (isChanged) {
                    this.podcastGroupEditViewStore.markSubPodcastAsChanged(this.podcastId);
                }
            }
        );

        this.reaction(
            () => this.coverImageUploadViewStore.hasChanged,
            (isChanged) => {
                if (isChanged) {
                    this.podcastGroupEditViewStore.markSubPodcastAsChanged(this.podcastId);
                }
            }
        );

        this.reaction(
            () => this.podcastGroupEditViewStore.form.$("isPremium").value,
            (isPodcastGroupPremium) => {
                if (isPodcastGroupPremium) {
                    this.form.$("isPremium").onChange(isPodcastGroupPremium);
                }
            }
        );

        this.reaction(
            () => this.podcastGroupEditViewStore.form.$("isVisibleInApp").value,
            (isVisibleInApp) => {
                this.form.$("isVisibleInApp").onChange(isVisibleInApp);
            }
        );

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (isPremium) {
                    this.form.$("producerId").addIsRequiredRule();
                } else {
                    this.form.$("producerId").removeIsRequiredRule();
                }
            }
        );

        if (this.form.$("isPremium").value === true) {
            this.form.$("producerId").addIsRequiredRule();
        }
    }

    @action.bound
    setForm() {
        this.form.set({
            ...this.podcast,
            genreIds: this.podcast.genres ? map(this.podcast.genres, (genre) => genre.id) : null,
            producerId: this.podcast.producerId,
        });
        this.genreSelectStore.setSelectedItem(this.podcast.genres || null);
        this.locationSelectStore.setSelectedItem(this.podcast.location);
        this.languageSelectStore.setSelectedItem(this.podcast.language);
        this.producerSelectStore.setSelectedItem(this.podcast.producer);
        if (this.podcast.coverImageId) {
            if (this.isNewlyAdded) {
                this.coverImageUploadViewStore.setUploadedFileId(this.podcast.coverImageId);
            } else {
                this.coverImageUploadViewStore.setImageUrl(
                    this.routeStore.getPodcastCoverImageUrl(this.podcast.coverImageId)
                );
            }
        } else {
            this.coverImageUploadViewStore.setUploadedFileId(null);
        }
    }

    resetForm() {
        this.form.clear();
        this.setForm();
        this.podcastGroupEditViewStore.markSubPodcastAsUpdated(this.podcast.id);
    }

    @action.bound
    onInit() {
        const subPodcastId = this.rootStore.routerStore.routerState.queryParams.subPodcastId;
        if (subPodcastId && this.podcast.id === subPodcastId) {
            this.isCollapsed = true;
        }
        return Promise.resolve();
    }

    create(data) {
        if (this.coverImageUploadViewStore.hasChanged) {
            data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        } else {
            data.coverImageId = this.podcast.coverImageId;
        }
        const selectedLocationOption = this.locationSelectStore.selectedOption;
        if (selectedLocationOption) {
            data.location = selectedLocationOption.item;
        }
        const selectedLanguageOption = this.languageSelectStore.selectedOption;
        if (selectedLanguageOption) {
            data.language = selectedLanguageOption.item;
        }
        const selectedGenres = this.genreSelectStore.selectedOption;
        if (selectedGenres) {
            data.genres = map(selectedGenres, (genreOption) => genreOption.item);
        }
        this.podcastGroupEditViewStore.updateSubPodcast(this.podcastId, data);
        this.form.$changed = false;
        this.form.each((field) => (field.$changed = false));
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    deleteSubPodcast = (event) => {
        // Prevent collapsing the podcast panel when a user clicks on the delete button.
        event.stopPropagation();
        event.preventDefault();
        this.podcastGroupEditViewStore.deleteSubPodcast(this.podcastId);
    };
}

export default PodcastGroupEditSubPodcastViewStore;
