import { action, computed, observable, runInAction } from "mobx";
import { applicationErrorCodes } from "common/constants";
import { MrbBasePreviewViewStore } from "mrb/core";
import { some } from "lodash";
import { localizationService } from "common/localization";

class NakiNigunArtistPreviewViewStore extends MrbBasePreviewViewStore {
    @observable displayMissingProducers = false;

    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunArtistCoverImageUrl(
                this.item.coverImageId,
                200,
                200
            );
        }

        return null;
    }

    @computed get isPremiumDisabled() {
        return some(this.item?.albums, (album) => album.producerId === null) && this.item.isPremium;
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            layoutActions: {
                edit: {
                    onClick: () =>
                        this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.edit", {
                            id: this.item.id,
                        }),
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.EDIT",
                    className: "c-btn c-btn--base c-btn--primary",
                },
            },
            navigateBack: "master.administration.naki-nigun.artist.list",
        });
        this.routeStore = routeStore;
    }

    get(id) {
        return this.routeStore.getNakiNigunArtist(id);
    }

    @action.bound
    async toggleIsActive(value) {
        const oldValue = this.item.isActive;
        this.item.isActive = value;
        try {
            await this.routeStore.updateActiveStatus(this.item.id, this.item.isActive);
        } catch (err) {
            if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
                this.rootStore.notificationStore.error(
                    "Artist updated, but an unexpected error occurred during RSS sync.",
                    err
                );
            } else {
                runInAction(() => {
                    this.item.isActive = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change status", err);
                });
            }
        }
    }

    @action.bound
    async togglePremiumStatus(value) {
        const oldValue = this.item.isPremium;
        this.item.isPremium = value;
        try {
            await this.routeStore.updatePremiumStatus(this.item.id, this.item.isPremium);
            this.setDisplayMissingProducers(false);
        } catch (err) {
            if (this.isPremiumDisabled) {
                this.setDisplayMissingProducers(true);
                runInAction(() => {
                    this.item.isPremium = oldValue;
                    this.rootStore.notificationStore.warning(
                        localizationService.t(
                            "ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.VALIDATION.ALBUM_MISSING_PRODUCER"
                        )
                    );
                });
            } else {
                runInAction(() => {
                    this.item.isPremium = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change premium", err);
                });
            }
        }
    }

    @action.bound
    async toggleVisibilityStatus(value) {
        const oldValue = this.item.isVisible;
        this.item.isVisible = value;
        try {
            await this.routeStore.updateVisibilityStatus(this.item.id, this.item.isVisible);
        } catch (err) {
            runInAction(() => {
                this.item.isVisible = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleMobileAppVisibilityStatus(value) {
        const oldValue = this.item.isVisible;
        this.item.isVisibleInApp = value;
        try {
            await this.routeStore.updateMobileAppVisibilityStatus(this.item.id, this.item.isVisibleInApp);
        } catch (err) {
            runInAction(() => {
                this.item.isVisibleInApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleFeaturedStatus(value) {
        const oldValue = this.item.isFeatured;
        this.item.isFeatured = value;
        try {
            await this.routeStore.updateFeaturedStatus(this.item.id, this.item.isFeatured);
        } catch (err) {
            runInAction(() => {
                this.item.isFeatured = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change featured status",
                    err
                );
            });
        }
    }

    @action.bound
    async deleteArtist() {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to delete artist?",
            onConfirm: async () => {
                try {
                    await this.routeStore.deleteArtist(this.item);
                    this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.list");
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred. Unable to delete artist.",
                        err
                    );
                }
            },
            yesLabel: "Delete",
            noLabel: "Cancel",
            description: "You won't be able to revert this action.",
        });
    }

    @action.bound
    setDisplayMissingProducers(value) {
        this.displayMissingProducers = value;
    }
}

export default NakiNigunArtistPreviewViewStore;
