import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbFieldTextArea, MrbFieldToggle, MrbEditPage, MrbFieldSelect } from "mrb/components";
import { StationCoverImageUpload, StationPromoEdit, StationPromoEditLoader } from "administration/station/components";
import { MrbFileUploadValidation } from "mrb/components/media";
import { MrbContentLoader } from "mrb/components/loader";
import { CoverImageUploadLoader, AddStationsOrPodcastsLoader, FieldRichTextEditor } from "common/components";
import { defaultTemplate } from "common/hoc";

function StationEditTemplate({ stationEditViewStore, t }) {
    const {
        form,
        coverImageUploadViewStore,
        loaderStore,
        locationSelectStore,
        languageSelectStore,
        genreSelectStore,
        stationPromoEditViewStore,
        overrideRadioUsersSettingsIsVisible,
        overrideAppUsersSettingsIsVisible,
    } = stationEditViewStore;
    const { fileUploadStore } = coverImageUploadViewStore;

    return (
        <MrbEditPage store={stationEditViewStore} t={t}>
            <div className="c-container--sidebar u-padd--none">
                <div className="c-card--primary">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AddStationsOrPodcastsLoader />}>
                        {() => (
                            <div className="u-animation--fadeIn">
                                <div className="row">
                                    <div className="col col-sml-12 col-lrg-6">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("title")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>
                                    <div className="col col-sml-12 col-lrg-6">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("streamUrl")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>
                                    <div className="col col-sml-12 col-lrg-6">
                                        <MrbFieldSelect
                                            t={t}
                                            store={locationSelectStore}
                                            classNameExtend="c-dropdown--dark"
                                        />
                                    </div>
                                    <div className="col col-sml-12 col-lrg-6">
                                        <MrbFieldSelect
                                            t={t}
                                            store={languageSelectStore}
                                            classNameExtend="c-dropdown--dark"
                                        />
                                    </div>
                                    <div className="col col-sml-12 col-xlrg-6">
                                        <MrbFieldSelect
                                            t={t}
                                            store={genreSelectStore}
                                            classNameExtend="c-dropdown--dark"
                                        />
                                    </div>
                                    <div className="col col-sml-12">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("keywords")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>
                                    <div className="col col-sml-12">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("descriptionLink")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>
                                    <div className="col col-sml-12">
                                        <MrbFieldTextArea
                                            t={t}
                                            rows={4}
                                            field={form.$("description")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="col col-sml-12">
                                        <FieldRichTextEditor field={form.$("alternativeDescription")} t={t} />
                                    </div>

                                    {form.$("isPremium").value ? (
                                        <div className="col col-sml-12">
                                            <FieldRichTextEditor field={form.$("premiumInfo")} t={t} />
                                        </div>
                                    ) : null}
                                </div>

                                <div className="c-form__footer__list">
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item"
                                        labelClassNameExtend="u-mar--top--tny"
                                        t={t}
                                        field={form.$("isActive")}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item"
                                        labelClassNameExtend="u-mar--top--tny"
                                        t={t}
                                        field={form.$("isPremium")}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item"
                                        labelClassNameExtend="u-mar--top--tny"
                                        t={t}
                                        field={form.$("isVisible")}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item"
                                        labelClassNameExtend="u-mar--top--tny"
                                        t={t}
                                        field={form.$("isTurnedOnByDefault")}
                                    />
                                    {overrideRadioUsersSettingsIsVisible ? (
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item"
                                            labelClassNameExtend="u-mar--top--tny"
                                            t={t}
                                            field={form.$("overrideRadioUsersSettings")}
                                        />
                                    ) : null}
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item"
                                        labelClassNameExtend="u-mar--top--tny"
                                        t={t}
                                        field={form.$("isTurnedOnByDefaultInApp")}
                                    />
                                    {overrideAppUsersSettingsIsVisible ? (
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item"
                                            labelClassNameExtend="u-mar--top--tny"
                                            t={t}
                                            field={form.$("overrideAppUsersSettings")}
                                        />
                                    ) : null}
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item"
                                        labelClassNameExtend="u-mar--top--tny"
                                        t={t}
                                        field={form.$("isFeatured")}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item"
                                        labelClassNameExtend="u-mar--top--tny"
                                        t={t}
                                        field={form.$("isVisibleInApp")}
                                    />
                                </div>
                            </div>
                        )}
                    </MrbContentLoader>
                </div>

                <div>
                    <div className="c-upload c-upload--lrg">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <CoverImageUploadLoader />}>
                            {() => (
                                <StationCoverImageUpload
                                    classNameExtendUpload="c-upload__upload--lrg"
                                    stationCoverImageUploadViewStore={coverImageUploadViewStore}
                                />
                            )}
                        </MrbContentLoader>
                    </div>
                    <MrbFileUploadValidation store={fileUploadStore} />
                </div>
            </div>

            <div className="c-container--sidebar u-padd--none u-mar--top--med">
                <div>
                    <h1 className="u-type--base u-mar--bottom--sml">Mobile App Promo Widget</h1>

                    <div className="c-card--primary">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <StationPromoEditLoader />}>
                            {() => <StationPromoEdit stationPromoEditViewStore={stationPromoEditViewStore} t={t} />}
                        </MrbContentLoader>
                    </div>
                </div>
            </div>
        </MrbEditPage>
    );
}

StationEditTemplate.propTypes = {
    stationEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(StationEditTemplate);
