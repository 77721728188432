import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbFieldToggle } from "mrb/components";
import { MrbContentLoader } from "mrb/components/loader";
import { MrbFileUploadValidation } from "mrb/components/media";
import {
    NakiNigunAddAlbum,
    NakiNigunArtistEditAlbumList,
    NakiNigunArtistEditPageLayout,
    NakiNigunArtistEditNameLoader,
    NakiNigunAddAlbumLoader,
} from "administration/naki-nigun/components";
import { CoverImageUploadLoader, CoverImageUpload } from "common/components";
import { defaultTemplate } from "common/hoc";

function NakiNigunArtistEditTemplate({ nakiNigunArtistEditViewStore, t }) {
    const { form, loaderStore, coverImageUploadViewStore } = nakiNigunArtistEditViewStore;
    const { fileUploadStore } = coverImageUploadViewStore;

    return (
        <NakiNigunArtistEditPageLayout store={nakiNigunArtistEditViewStore} t={t}>
            <div className="c-container--sidebar u-padd--none u-mar--bottom--med">
                <div>
                    <div className="c-card--primary">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <NakiNigunArtistEditNameLoader />}
                        >
                            {() => (
                                <form onSubmit={form.onSubmit}>
                                    <div className="u-animation--fadeIn">
                                        <div className="row">
                                            <div className="col col-sml-12">
                                                <MrbFieldInput
                                                    t={t}
                                                    field={form.$("name")}
                                                    classNameExtend="c-input--outline"
                                                />
                                            </div>
                                        </div>
                                        <div className="c-form__footer__list">
                                            <MrbFieldToggle
                                                classNameExtend="c-toggle__item"
                                                labelClassNameExtend="u-mar--top--tny"
                                                t={t}
                                                field={form.$("isActive")}
                                            />
                                            <MrbFieldToggle
                                                classNameExtend="c-toggle__item"
                                                labelClassNameExtend="u-mar--top--tny"
                                                t={t}
                                                field={form.$("isPremium")}
                                            />
                                            <MrbFieldToggle
                                                classNameExtend="c-toggle__item"
                                                labelClassNameExtend="u-mar--top--tny"
                                                t={t}
                                                field={form.$("isVisible")}
                                            />
                                            <MrbFieldToggle
                                                classNameExtend="c-toggle__item"
                                                labelClassNameExtend="u-mar--top--tny"
                                                t={t}
                                                field={form.$("isVisibleInApp")}
                                            />
                                            <MrbFieldToggle
                                                classNameExtend="c-toggle__item"
                                                labelClassNameExtend="u-mar--top--tny"
                                                t={t}
                                                field={form.$("isFeatured")}
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </MrbContentLoader>
                    </div>
                </div>

                <div>
                    <div className="c-upload c-upload--lrg">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <CoverImageUploadLoader />}>
                            {() => (
                                <CoverImageUpload
                                    classNameExtendUpload="c-upload__upload--lrg"
                                    coverImageUploadViewStore={coverImageUploadViewStore}
                                    showInfo
                                />
                            )}
                        </MrbContentLoader>
                    </div>
                    <MrbFileUploadValidation store={fileUploadStore} />
                </div>
            </div>

            <div className="u-mar--bottom--med">
                <h1 className="u-type--base u-mar--bottom--sml">
                    {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.ADD_ALBUMS.TITLE")}
                </h1>
            </div>

            <div className="c-card--primary u-mar--bottom--xlrg">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <NakiNigunAddAlbumLoader />}>
                    {() => <NakiNigunAddAlbum nakiNigunArtistEditViewStore={nakiNigunArtistEditViewStore} />}
                </MrbContentLoader>
            </div>

            <div>
                <NakiNigunArtistEditAlbumList nakiNigunArtistEditViewStore={nakiNigunArtistEditViewStore} t={t} />
            </div>
        </NakiNigunArtistEditPageLayout>
    );
}

NakiNigunArtistEditTemplate.propTypes = {
    nakiNigunArtistEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(NakiNigunArtistEditTemplate);
