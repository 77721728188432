import { moduleProviderFactory } from "mrb/core";
import { NakiMobileHomeSectionList, NakiMobileFeaturedSection } from "administration/naki-mobile/pages";
import {
    NakiMobileHomeSectionListRouteStore,
    NakiMobileFeaturedSectionRouteStore,
} from "administration/naki-mobile/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "NakiMobile",
        routes: [
            {
                name: "master.administration.naki-mobile",
                pattern: "/naki-mobile",
                children: [
                    {
                        name: "master.administration.naki-mobile.sections",
                        pattern: "sections",
                        store: NakiMobileHomeSectionListRouteStore,
                        component: NakiMobileHomeSectionList,
                        data: {
                            title: "Home Screen Sections",
                        },
                    },
                    {
                        name: "master.administration.naki-mobile.featured-section",
                        pattern: "featured-section",
                        store: NakiMobileFeaturedSectionRouteStore,
                        component: NakiMobileFeaturedSection,
                        data: {
                            title: "Featured Section",
                        },
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.NAKI_MOBILE",
                order: 9,
                icon: "mobile",
                subMenu: [
                    {
                        title: "ADMINISTRATION.MENU.NAKI_MOBILE_HOME_SCREEN",
                        order: 1,
                        route: "master.administration.naki-mobile.sections",
                    },
                    {
                        title: "ADMINISTRATION.MENU.NAKI_MOBILE_FEATURED_SECTION",
                        order: 2,
                        route: "master.administration.naki-mobile.featured-section",
                    },
                ],
            },
        ],
    });
})();
