import { MrbBaseRouteStore } from "mrb/core";
import { StationService, StationFileStreamService, StationAnalyticsService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class StationPreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(StationService);
        this.stationFileStreamService = this.rootStore.createApplicationService(StationFileStreamService);
        this.stationAnalyticsService = this.rootStore.createApplicationService(StationAnalyticsService);
    }

    async getStation(id) {
        const response = await this.service.get(id, {
            embed: "location,language,genres,stationPromo",
        });
        return response.data;
    }

    async getStationAnalyticsStats(id, options) {
        const response = await this.stationAnalyticsService.getStationStats(id, options);
        return response.data;
    }

    async updateActiveStatus(id, isActive) {
        const response = await this.service.updateActiveStatus(id, isActive);
        return response.data;
    }

    async updatePremiumStatus(id, isPremium) {
        const response = await this.service.updatePremiumStatus(id, isPremium);
        return response.data;
    }

    async updateOnByDefaultStatus(id, isOnByDefault) {
        const response = await this.service.updateOnByDefaultStatus(id, isOnByDefault);
        return response.data;
    }

    async updateOnByDefaultInAppStatus(id, isOnByDefault) {
        const response = await this.service.updateOnByDefaultInAppStatus(id, isOnByDefault);
        return response.data;
    }

    async updateVisibilityStatus(id, isVisible) {
        const response = await this.service.updateVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateAppVisibilityStatus(id, isVisible) {
        const response = await this.service.updateAppVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateFeaturedStatus(id, isFeatured) {
        const response = await this.service.updateFeaturedStatus(id, isFeatured);
        return response.data;
    }

    async deleteStation(resource) {
        const response = await this.service.delete(resource);
        return response.data;
    }

    createStationCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.stationFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }
}

export default StationPreviewRouteStore;
