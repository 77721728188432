import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader } from "mrb/components/layout";
import { MrbToggle } from "mrb/components/checkbox";
import { MrbButton } from "mrb/components/button";
import {
    StationAnalyticsStatsWidget,
    StationPromoPreview,
    StationsPreviewDescriptionLoader,
} from "administration/station/components";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";
import {
    PreviewContentLoader,
    HTMLRender,
    Genres,
    DescriptionLoader,
    ToggleActionsLoader,
    Image,
} from "common/components";
import { isNil } from "lodash";

function StationPreviewTemplate({ stationPreviewViewStore, t }) {
    const {
        item,
        coverImageUrl,
        toggleIsActive,
        togglePremiumStatus,
        toggleOnByDefaultStatus,
        toggleOnByDefaultInAppStatus,
        toggleVisibilityStatus,
        toggleFeaturedStatus,
        toggleAppVisibilityStatus,
        loaderStore,
        deleteStation,
        id,
        routeStore,
    } = stationPreviewViewStore;

    return (
        <MrbPreviewPage store={stationPreviewViewStore} contentClassNameExtend="u-padd--none" t={t}>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div>
                                <div className="u-animation--fadeIn">
                                    <Image src={coverImageUrl} />
                                </div>
                            </div>
                            <div className="c-content__header--primary__info">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.TITLE")}
                                        </p>
                                        <p className="c-content__header--primary__title">{item.title}</p>
                                    </div>
                                </div>

                                <div className="u-animation--fadeIn">
                                    <div className="c-content__header--primary__descriprtion">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.DESCRIPTION_LINK")}
                                        </p>
                                        {item.descriptionLink && (
                                            <a href={item.descriptionLink} target="_blank" rel="noreferrer">
                                                {item.descriptionLink}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <div className="c-container--sidebar">
                <div>
                    <div className="c-card--primary c-card--med u-mar--bottom--med">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <DescriptionLoader />}>
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <div className="row">
                                        <div className="col col-sml-12">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.PORTAL_DESCRIPTION")}
                                                </p>
                                                <HTMLRender className="c-collapse-panel__body__description">
                                                    {item.alternativeDescription}
                                                </HTMLRender>
                                            </div>
                                        </div>

                                        <div className="col col-sml-12">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.PREMIUM_INFO")}
                                                </p>
                                                <HTMLRender className="c-collapse-panel__body__description">
                                                    {item.premiumInfo}
                                                </HTMLRender>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>

                    <div className="c-card--primary c-card--med u-mar--bottom--med">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <PreviewContentLoader row={3} />}
                        >
                            {() => (
                                <React.Fragment>
                                    <div className="u-animation--fadeIn">
                                        <div className="row">
                                            <div className="col col-sml-12 col-lrg-6">
                                                <div className="u-type--base u-type--color--primary u-mar--bottom--sml">
                                                    <p className="u-type--sml u-type--color--opaque">
                                                        {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.STREAM_URL")}
                                                    </p>
                                                    <a
                                                        href={item.streamUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="u-type--link"
                                                    >
                                                        {item.streamUrl}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col col-sml-6 u-mar--bottom--sml">
                                                <div className="u-type--base u-mar--bottom--sml">
                                                    <p className="u-type--sml u-type--color--opaque">
                                                        {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.LOCATION")}
                                                    </p>
                                                    <p className="u-type--base">
                                                        {item.location && item.location.name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col col-sml-6 u-mar--bottom--sml">
                                                <div className="u-type--base u-mar--bottom--sml">
                                                    <p className="u-type--sml u-type--color--opaque">
                                                        {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.LANGUAGE")}
                                                    </p>
                                                    <p className="u-type--base">
                                                        {item.language && item.language.name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col col-sml-12 col-lrg-6">
                                                <div className="u-type--base u-mar--bottom--sml">
                                                    <p className="u-type--sml u-type--color--opaque">
                                                        {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.GENRES")}
                                                    </p>
                                                    <Genres genres={item.genres} />
                                                </div>
                                            </div>
                                            <div className="col col-sml-12 col-lrg-6">
                                                <div className="u-type--base u-mar--bottom--sml">
                                                    <p className="u-type--sml u-type--color--opaque">
                                                        {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.DESCRIPTION")}
                                                    </p>
                                                    <p className="u-type--base">{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </MrbContentLoader>
                    </div>

                    <MrbContentLoader
                        loaderStore={loaderStore}
                        loaderRender={() => <StationsPreviewDescriptionLoader />}
                    >
                        {() => (
                            <React.Fragment>
                                {!isNil(item.stationPromo) ? (
                                    <div className="u-animation--fadeIn">
                                        <div className="c-card--primary">
                                            <StationPromoPreview item={item.stationPromo} t={t} />
                                        </div>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        )}
                    </MrbContentLoader>
                </div>

                <div>
                    <div className="row">
                        <div className="col col-sml-12 col-med-6 col-xxlrg-12">
                            <div className="c-card--primary u-mar--bottom--med">
                                <MrbContentLoader
                                    loaderStore={loaderStore}
                                    loaderRender={() => (
                                        <ToggleActionsLoader lineheight={25} smallWidth={true} rows={6} />
                                    )}
                                >
                                    {() => (
                                        <div className="u-animation--fadeIn">
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.BUTTONS.STATUS")}
                                                </div>
                                                <MrbToggle
                                                    id="station-active-status"
                                                    value={item.isActive}
                                                    onChange={(event) => toggleIsActive(event.target.checked)}
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.BUTTONS.PREMIUM")}
                                                </div>
                                                <MrbToggle
                                                    id="station-premium-status"
                                                    value={item.isPremium}
                                                    onChange={(event) => togglePremiumStatus(event.target.checked)}
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.BUTTONS.MAKE_DEFAULT")}
                                                </div>
                                                <MrbToggle
                                                    id="station-on-by-default-status"
                                                    value={item.isTurnedOnByDefault}
                                                    onChange={(event) => toggleOnByDefaultStatus(event.target.checked)}
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.BUTTONS.MAKE_DEFAULT_IN_APP")}
                                                </div>
                                                <MrbToggle
                                                    id="station-on-by-default-in-app-status"
                                                    value={item.isTurnedOnByDefaultInApp}
                                                    onChange={(event) =>
                                                        toggleOnByDefaultInAppStatus(event.target.checked)
                                                    }
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.BUTTONS.VISIBLE_ON_PORTAL")}
                                                </div>
                                                <MrbToggle
                                                    id="station-visibility-status"
                                                    value={item.isVisible}
                                                    onChange={(event) => toggleVisibilityStatus(event.target.checked)}
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.BUTTONS.FEATURED_ON_PORTAL")}
                                                </div>
                                                <MrbToggle
                                                    id="station-featured-status"
                                                    value={item.isFeatured}
                                                    onChange={(event) => toggleFeaturedStatus(event.target.checked)}
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.BUTTONS.VISIBLE_IN_APP")}
                                                </div>
                                                <MrbToggle
                                                    id="station-app-visibility-status"
                                                    value={item.isVisibleInApp}
                                                    onChange={(event) =>
                                                        toggleAppVisibilityStatus(event.target.checked)
                                                    }
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.STATION.PREVIEW.DELETE_LABEL")}
                                                </div>
                                                <MrbButton
                                                    t={t}
                                                    type="button"
                                                    className="c-btn c-btn--primary c-btn--sml"
                                                    label="ADMINISTRATION.STATION.PREVIEW.BUTTONS.DELETE"
                                                    onClick={deleteStation}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </MrbContentLoader>
                            </div>
                        </div>

                        <div className="col col-sml-12 col-med-6 col-xxlrg-12">
                            <div className="c-card--primary">
                                <StationAnalyticsStatsWidget stationId={id} routeStore={routeStore} t={t} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MrbPreviewPage>
    );
}

StationPreviewTemplate.propTypes = {
    stationPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(StationPreviewTemplate);
