import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";
import { MrbToggle } from "mrb/components/checkbox";
import { Genres, Image, ProducerLabel } from "common/components";
import { isNil } from "lodash";

function NakiNigunArtistPreviewAlbumTemplate({ nakiNigunArtistPreviewAlbumViewStore, t }) {
    const {
        album,
        isCollapsed,
        toggleCollapse,
        toggleIsActive,
        togglePremiumStatus,
        toggleOnByDefaultStatus,
        toggleOnByDefaultInAppStatus,
        toggleIsFeatured,
        onClickEdit,
        setPanelRef,
        coverImageUrl,
        isPremiumDisabled,
        isActiveStatusDisabled,
        onClickAlbumTrackList,
        toggleHlsEnabledStatus,
    } = nakiNigunArtistPreviewAlbumViewStore;
    const { displayMissingProducers } = nakiNigunArtistPreviewAlbumViewStore.nakiNigunArtistPreviewViewStore;

    return (
        <MrbCollapsiblePanel
            title={album.title}
            innerRef={setPanelRef}
            t={t}
            isCollapsed={isCollapsed}
            onChangeCollapse={toggleCollapse}
            headerClassNameExtend="u-cursor--pointer"
            headerRender={(props) => (
                <AlbumPanelHeader
                    {...props}
                    album={album}
                    displayMissingProducers={displayMissingProducers}
                    isPremiumDisabled={isPremiumDisabled}
                />
            )}
        >
            {() => (
                <React.Fragment>
                    <div className="row u-padd--none">
                        <div className="col col-sml-12 col-lrg-8">
                            <div className="row">
                                <div className="col col-sml-12 col-xxlrg-8 col u-mar--bottom--sml">
                                    <div className="c-collapse-panel__body__link">
                                        <p className="c-collapse-panel__body__label">
                                            {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.FIELDS.FEED_URL")}
                                        </p>
                                        <a href={album.albumFeed.feedUrl} target="_blank" rel="noreferrer">
                                            {album.albumFeed.feedUrl}
                                        </a>
                                    </div>
                                </div>

                                <div className="col col-sml-6 col-xxlrg-1 u-mar--bottom--sml">
                                    <p className="c-collapse-panel__body__label">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.FIELDS.YEAR")}
                                    </p>
                                    {album.year}
                                </div>

                                <div className="col col-sml-6 col-xxlrg-3 u-mar--bottom--sml">
                                    <p className="c-collapse-panel__body__label">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.FIELDS.GENRES")}
                                    </p>
                                    <Genres genres={album.genres} />
                                </div>

                                <div className="col col-sml-12 u-display--flex u-display--flex--wrap">
                                    <div className="u-type--sml u-mar--right--lrg">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.STATUS")}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`album-active-status-${album.id}`}
                                            value={album.isActive}
                                            disabled={isActiveStatusDisabled}
                                            onChange={(event) => toggleIsActive(event.target.checked)}
                                        />
                                    </div>

                                    <div className="u-type--sml u-mar--bottom--sml u-mar--right--lrg">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.PREMIUM")}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`album-premium-status-${album.id}`}
                                            value={album.isPremium}
                                            disabled={isPremiumDisabled}
                                            onChange={(event) => togglePremiumStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="u-type--sml u-mar--bottom--sml u-mar--right--lrg">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.MAKE_DEFAULT")}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`album-on-by-default-status-${album.id}`}
                                            value={album.isTurnedOnByDefault}
                                            onChange={(event) => toggleOnByDefaultStatus(event.target.checked)}
                                        />
                                    </div>
                                    <div className="u-type--sml u-mar--bottom--sml u-mar--right--lrg">
                                        {t(
                                            "ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.MAKE_DEFAULT_IN_APP"
                                        )}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`album-on-by-default-in-app-status-${album.id}`}
                                            value={album.isTurnedOnByDefaultInApp}
                                            onChange={(event) => toggleOnByDefaultInAppStatus(event.target.checked)}
                                        />
                                    </div>
                                    <div className="u-type--sml u-mar--right--lrg">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.FEATURED")}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`album-featured-status-${album.id}`}
                                            value={album.isFeatured}
                                            onChange={(event) => toggleIsFeatured(event.target.checked)}
                                        />
                                    </div>
                                    <div className="u-type--sml u-mar--right--lrg">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.HLS_ENABLED")}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`album-hls-enabled-status-${album.id}`}
                                            value={album.albumFeed.hlsEnabled}
                                            onChange={(event) => toggleHlsEnabledStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="u-type--base u-mar--bottom--sml u-mar--right--med">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t(
                                                "ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.FIELDS.CONTENT_SORT_TYPE"
                                            )}
                                        </p>
                                        <p className="u-type--base">
                                            {album.albumFeed &&
                                                album.albumFeed.contentSortType &&
                                                album.albumFeed.contentSortType.name}
                                        </p>
                                    </div>

                                    <div className="u-type--base u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.FIELDS.PRODUCER")}
                                        </p>
                                        <p className="u-type--base">
                                            {album.producer && (
                                                <ProducerLabel
                                                    name={album.producer.name}
                                                    isUser={!isNil(album.producer.user)}
                                                    user={album.producer.user}
                                                />
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col col-sml-12 col-lrg-4">
                            <div>
                                <p className="c-collapse-panel__body__label">
                                    {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.FIELDS.COVER_IMAGE")}
                                </p>
                                <Image className="c-upload__uploaded-img" src={coverImageUrl} />
                            </div>
                            {/* <div className="row u-mar--top--sml">
                                <PodcastGroupPreviewSubPodcastAnalyticsStats
                                    store={subPodcastAnalyticsStatsWidgetViewStore}
                                    t={t}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="u-separator--primary"></div>
                    <MrbButton
                        onClick={onClickEdit}
                        label="ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.ALBUM_EDIT"
                        t={t}
                    />{" "}
                    <MrbButton
                        onClick={onClickAlbumTrackList}
                        className="c-btn c-btn--base c-btn--ghost"
                        label="ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.BUTTONS.SONGS"
                        t={t}
                    />
                </React.Fragment>
            )}
        </MrbCollapsiblePanel>
    );
}

NakiNigunArtistPreviewAlbumTemplate.propTypes = {
    album: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const AlbumPanelHeader = observer(function AlbumPanelHeader({
    title,
    t,
    isCollapsed,
    album,
    displayMissingProducers,
    isPremiumDisabled,
}) {
    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--ai--center">
                {t(title)}{" "}
                {album.isPremium && (
                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                )}
            </div>
            <div className="u-display--flex u-display--flex--ai--center">
                {((isPremiumDisabled && album.producerId === null) ||
                    (displayMissingProducers && album.producerId === null)) && (
                    <p className="u-type--sml u-type--color--warning--light u-mar--right--lrg">
                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.VALIDATION.MISSING_PRODUCER_LABEL")}
                    </p>
                )}{" "}
                <i
                    className={`u-icon u-icon--med u-icon--arrow-down ${
                        isCollapsed ? "u-rotate--180" : ""
                    } u-cursor--pointer`}
                ></i>
            </div>
        </React.Fragment>
    );
});

export default observer(NakiNigunArtistPreviewAlbumTemplate);
