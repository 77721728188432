import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { MrbEditFormLayout } from "mrb/components/layout";
import { MrbFieldSelect } from "mrb/components/select";
import { MrbFieldInput, MrbFieldTextArea, MrbFieldNumericInput } from "mrb/components/input";
import { MrbFieldToggle } from "mrb/components/checkbox";
import { MrbButton } from "mrb/components/button";
import { MrbFieldRadioButtons } from "mrb/components/radio";
import { PodcastCoverImageUpload } from "administration/podcast/components";
import { PODCAST_FORM_STATUS } from "administration/podcast/stores";
import { FieldRichTextEditor, ProducerFieldSelect } from "common/components";
import { observer } from "mobx-react";

function PodcastGroupEditSubPodcastTemplate({ podcastGroupEditSubPodcastViewStore, t }) {
    const {
        form,
        podcastIndex,
        podcast,
        episodeSortTypeOptions,
        isCollapsed,
        toggleCollapse,
        setPanelRef,
        coverImageUploadViewStore,
        resetForm,
        deleteSubPodcast,
        genreSelectStore,
        languageSelectStore,
        locationSelectStore,
        producerSelectStore,
        overrideRadioUsersSettingsIsVisible,
        overrideAppUsersSettingsIsVisible,
        isPremiumDisabled,
    } = podcastGroupEditSubPodcastViewStore;
    return (
        <React.Fragment>
            <MrbCollapsiblePanel
                innerRef={setPanelRef}
                title={podcast.title}
                t={t}
                isCollapsed={isCollapsed}
                onChangeCollapse={toggleCollapse}
                headerRender={(props) => (
                    <SubPodcastPanelHeader
                        {...props}
                        podcast={podcast}
                        podcastIndex={podcastIndex}
                        deleteSubPodcast={deleteSubPodcast}
                    />
                )}
            >
                {() => (
                    <MrbEditFormLayout
                        form={form}
                        t={t}
                        onClickCancel={resetForm}
                        cancelLabel="Cancel"
                        submitLabel="Update"
                    >
                        <div className="row">
                            <div className="col col-sml-12 col-lrg-8 col-xxlrg-9">
                                <MrbFieldInput t={t} field={form.$("title")} classNameExtend="c-input--outline" />

                                <MrbFieldInput
                                    t={t}
                                    field={form.$("podcastFeed.feedUrl")}
                                    classNameExtend="c-input--outline"
                                />

                                <MrbFieldInput
                                    t={t}
                                    field={form.$("descriptionLink")}
                                    classNameExtend="c-input--outline"
                                />
                            </div>

                            <div className="col col-sml-12 col-lrg-4 col-xxlrg-3 c-upload--order-3">
                                <div className="c-upload c-upload--sml">
                                    <PodcastCoverImageUpload
                                        classNameExtendUpload="c-upload__upload--sml"
                                        coverImageUploadViewStore={coverImageUploadViewStore}
                                        showInfo={false}
                                    />
                                </div>
                            </div>

                            <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                                <MrbFieldSelect t={t} store={locationSelectStore} classNameExtend="c-dropdown--dark" />
                            </div>
                            <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                                <MrbFieldSelect t={t} store={languageSelectStore} classNameExtend="c-dropdown--dark" />
                            </div>
                            <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                                <MrbFieldSelect t={t} store={genreSelectStore} classNameExtend="c-dropdown--dark" />
                            </div>
                            <div className="col col-sml-12 col-med-6 col-xxlrg-3">
                                <ProducerFieldSelect
                                    t={t}
                                    store={producerSelectStore}
                                    classNameExtend="c-dropdown--dark"
                                />
                            </div>

                            <div className="col col-sml-12">
                                <MrbFieldInput t={t} field={form.$("keywords")} classNameExtend="c-input--outline" />
                            </div>

                            <div className="col col-sml-12">
                                <MrbFieldTextArea
                                    t={t}
                                    field={form.$("description")}
                                    classNameExtend="c-input--outline"
                                />
                            </div>

                            <div className="col col-sml-12">
                                <FieldRichTextEditor field={form.$("alternativeDescription")} t={t} />
                            </div>
                        </div>

                        <div className="c-form__footer__list">
                            <MrbFieldToggle
                                classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                                labelClassNameExtend="u-mar--top--tny"
                                t={t}
                                field={form.$("isActive")}
                            />
                            <MrbFieldToggle
                                classNameExtend="u-mar--right--lrg"
                                labelClassNameExtend="u-mar--top--tny"
                                t={t}
                                field={form.$("isPremium")}
                                disabled={isPremiumDisabled}
                            />
                            <MrbFieldToggle
                                classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                                labelClassNameExtend="u-mar--top--tny"
                                t={t}
                                field={form.$("isTurnedOnByDefault")}
                            />
                            {overrideRadioUsersSettingsIsVisible ? (
                                <MrbFieldToggle
                                    classNameExtend="c-toggle__item"
                                    labelClassNameExtend="u-mar--top--tny"
                                    t={t}
                                    field={form.$("overrideRadioUsersSettings")}
                                />
                            ) : null}
                            <MrbFieldToggle
                                classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                                labelClassNameExtend="u-mar--top--tny"
                                t={t}
                                field={form.$("isTurnedOnByDefaultInApp")}
                            />
                            {overrideAppUsersSettingsIsVisible ? (
                                <MrbFieldToggle
                                    classNameExtend="c-toggle__item"
                                    labelClassNameExtend="u-mar--top--tny"
                                    t={t}
                                    field={form.$("overrideAppUsersSettings")}
                                />
                            ) : null}
                            <MrbFieldToggle
                                classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                                labelClassNameExtend="u-mar--top--tny"
                                t={t}
                                field={form.$("podcastFeed.recentlyUpdatedVisibility")}
                            />
                            <MrbFieldToggle
                                classNameExtend="u-mar--right--lrg u-mar--bottom--tny"
                                labelClassNameExtend="u-mar--top--tny"
                                t={t}
                                field={form.$("isVisibleInApp")}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item"
                                labelClassNameExtend="u-mar--top--tny"
                                t={t}
                                field={form.$("podcastFeed.hlsEnabled")}
                            />
                            <div className="u-mar--right--lrg">
                                <MrbFieldRadioButtons
                                    field={form.$("podcastFeed.episodeSortTypeId")}
                                    t={t}
                                    options={episodeSortTypeOptions}
                                />
                            </div>

                            <MrbFieldNumericInput precision={0} t={t} field={form.$("podcastFeed.episodeLimit")} />
                        </div>

                        <div className="u-separator--primary"></div>
                    </MrbEditFormLayout>
                )}
            </MrbCollapsiblePanel>
        </React.Fragment>
    );
}

PodcastGroupEditSubPodcastTemplate.propTypes = {
    podcastGroupEditSubPodcastViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const SubPodcastPanelHeader = observer(function SubPodcastPanelHeader({
    title,
    t,
    isCollapsed,
    podcastIndex,
    podcast,
    deleteSubPodcast,
}) {
    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--ai--center">
                <i className="u-icon u-icon--med u-icon--drag u-mar--right--tny"></i>
                <span className="u-type--wgt--bold u-mar--right--tny">{podcastIndex}.</span>
                {t(title)}
                {podcast.isPremium && (
                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                )}
            </div>
            <div className="u-display--flex u-display--flex--ai--center">
                {podcast.FORM_STATUS === PODCAST_FORM_STATUS.CHANGED && (
                    <div className="c-validation--info c-validation--info--secondary">
                        <p className="c-validation__label">
                            {t("ADMINISTRATION.PODCAST.EDIT.VALIDATION.SUB_PODCAST_CHANGED")}
                        </p>
                    </div>
                )}
                <div className="u-display--flex u-display--flex--ai--center">
                    <MrbButton
                        t={t}
                        label="ADMINISTRATION.PODCAST.EDIT.SUBPODCAST.BUTTONS.DELETE"
                        onClick={deleteSubPodcast}
                        classNameOverride="c-btn c-btn--sml c-btn--ghost u-mar--right--tny"
                    />
                    <i
                        className={`u-icon u-icon--med u-icon--arrow-down ${
                            isCollapsed ? "u-rotate--180" : ""
                        } u-cursor--pointer u-mar--top--micro--alt`}
                    ></i>
                </div>
            </div>
        </React.Fragment>
    );
});

export default observer(PodcastGroupEditSubPodcastTemplate);
